.swiper_container {
  border-radius: 2rem;
  width: 900px;
}
@media (max-width: 900px) {
  .swiper_container {
    width: 710px;
  }
  .swiper_slide_img {
    width: 18rem !important;
    height: 24rem !important;
  }
}
@media (max-width: 700px) {
  .swiper_container {
    width: auto;
  }
}
.swiper_slide {
  width: auto;
  position: relative;
}
@media (max-width: 500px) {
  .swiper_slide_img {
    width: 17rem !important;
    height: 22rem !important;
  }
  .swiper_slide_button {
    font-size: 12px;
  }
}
.swiper_slide_div {
  position: relative;
  display: flex;
  justify-content: center;
}

.swiper_slide_img {
  width: 23rem;
  height: 28rem;
  border-radius: 2rem;
}
.swiper_slide_button {
  position: absolute;
  width: 75%;
  top: 80%;
  background-color: rgb(222, 18, 133);
}
