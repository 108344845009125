.card {
    float: left;
    /* padding: 0 1.7rem; */
    width: 100%;
    .menu-content {
      @include cf;
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        display: inline-block;
      }
      a {
        color: white;
      }
      span {
        position: absolute;
        left: 50%;
        top: 0;
        font-size: 10px;
        font-weight: 700;
        font-family: 'Open Sans';
        transform: translate(-50%, 0);
      }
    }
    .wrapper {
      background-color: white;
      min-height: 340px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 19px 38px rgba(black, 0.3), 0 15px 12px rgba(black, 0.2);
      &:hover {
        .data {
          transform: translateY(0);
        }
      }
    }
    .data {
      position: absolute;
      bottom: 0;
      width: 100%;
      transform: translateY(calc(0px + 0.5rem));
      transition: transform 0.3s;
      .content {
        padding: 1em;
        position: relative;
        z-index: 1;
      }
    }
    .author {
      font-size: 12px;
    }
    .title {
      margin-top: 10px;
    }
    .text {
      height: 70px;
      margin: 0;
    }
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox']:checked + .menu-content {
      transform: translateY(-60px);
    }
  }
  
  /* First example styles */
  .example-1 {
    /* .wrapper {
      background: url(https://images.unsplash.com/photo-1496979551903-46e46589a88b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=cda12b505afa1beb06e49d89014cbd65&auto=format&fit=crop&w=634&q=80) 20% 1% / cover no-repeat;
    } */
    .date {
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      color: white;
      padding: 0.8em;
      span {
        display: block;
        text-align: center;
      }
      .day {
        font-weight: 700;
        font-size: 24px;
        text-shadow: 2px 3px 2px rgba(black, 0.18);
      }
      .month {
        text-transform: uppercase;
      }
      .month,
      .year {
        font-size: 12px;
      }
    }
    .content {
      background-color: white;
      box-shadow: 0 5px 30px 10px rgba(black, 0.3);
    }
    .title {
      a {
        color: lighten(black, 50%);
      }
    }
    .menu-button {
      position: absolute;
      z-index: 999;
      top: 16px;
      right: 16px;
      width: 25px;
      text-align: center;
      cursor: pointer;
      span {
        width: 5px;
        height: 5px;
        background-color: lighten(black, 50%);
        color: lighten(black, 50%);
        position: relative;
        display: inline-block;
        border-radius: 50%;
        &::after,
        &::before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          background-color: currentColor;
          position: absolute;
          border-radius: 50%;
        }
        &::before {
          left: -10px;
        }
        &::after {
          right: -10px;
        }
      }
    }
    .menu-content {
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      transition: transform 0.3s;
      transform: translateY(0);
      li {
        width: 33.333333%;
        float: left;
        background-color: bermuda;
        height: 60px;
        position: relative;
      }
      a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
      }
      span {
        top: -10px;
      }
    }
  }
  
 