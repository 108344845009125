/* Add a black background color to the top navigation */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f8f7f4;
}

.topnav {
  position: relative;
  background-color: #f8f7f4;
  overflow: hidden;
  padding: 10px;
  z-index: 1;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 8px;
  text-decoration: none;
  font-size: 13px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: transparent;
  color: rgb(107, 107, 107);
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: transparent;
  color: white;
  padding: 10px;
}

/* Centered section inside the top navigation */
.topnav-centered a {
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Right-aligned section inside the top navigation */
.topnav-right {
  float: right;
}

/* Responsive navigation menu - display links on top of each other instead of next to each other (for mobile devices) */
@media screen and (max-width: 1025px) {
  .topnav a,
  .topnav-right {
    float: none;
    display: block;
  }

  .topnav-centered a {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
}
/* Hamburger Icon */
.hamburger-icon {
  display: none; /* Hide by default on larger screens */
  cursor: pointer;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: #333; /* Adjust the color as needed */
  margin: 6px 0;
  transition: 0.4s;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 1025px) {
  /* Show the hamburger icon and hide the navigation links */
  .hamburger-icon {
    display: block;
    margin-top: 30px;
  }

  .nav-links {
    display: none;
  }

  /* Add styles to make the icon more visible on a light background */
  .bar {
    background-color: black; /* Adjust the color as needed */
  }

  /* Toggle the navigation links when the hamburger icon is clicked */
  .nav-links.show {
    display: block;
  }
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.btn-navbar {
  display: flex;
  justify-content: center;
}
.commingsoon {
  position: relative;
}
.commingsoon_icon {
  position: absolute;
  top: -10px;
  left: 60px;
  /* transform: translateX(-50%); */
}
.P_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commingsoon_Picon {
  position: absolute;
  top: -10px; /* Adjust the top value to your preferred position */
  left: 130px; /* Center the image horizontally */
  /* transform: translateX(-50%); */
}

@media (max-width: 1025px) {
  .commingsoon_Picon {
    top: 10;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 1025px) {
  .commingsoon_icon {
    left: 50%;
    transform: translateX(-50%);
  }
}
/* .slider-item {
  margin-right: 30px; 
 
}
.card-with-gap {
  margin-right: 20px;
} */

.slider-container {
  position: relative;
}

/* Add this CSS class to create the gap effect between cards */
.slider-item-gap {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adjust the shadow to control the gap width */
  transform: translateX(
    10px
  ); /* Adjust the translation to match the shadow width */
}

.hero-marquee-item {
  border-right: 10px solid transparent; /* Adjust the border width as needed for spacing between cards */
}

.hero-marquee {
  --grid-gap: 24px;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 72px 0;
  overflow: hidden;
  background-color: #f8f7f4;
}
.hero-marquee__track {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-animation: marquee-mobile
    calc(var(--card-speed, 3s) * var(--card-count, 6)) linear infinite;
  animation: marquee-mobile linear infinite;
  transition: transform 0.5s ease-in-out;
}

.hero-marquee__grid {
  display: grid;
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  gap: var(--grid-gap);
}

.hero-marquee-item.lazyloaded {
  --card-opacity: 1;
  --card-z-offset: 0;
}
.hero-marquee-item {
  --card-media-width: 218px;
  --card-media-height: 272px;
  position: relative;
  width: var(--card-media-width);
  height: var(--card-media-height);
  overflow: hidden;
  -webkit-transform: perspective(300px)
    translate3d(0, 0, var(--card-z-offset, 16px));
  transform: perspective(300px) translate3d(0, 0, var(--card-z-offset, 16px));
  -webkit-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  -webkit-transition-delay: var(--reveal-delay, 0s);
  transition-delay: var(--reveal-delay, 0s);
  border-radius: 26px;
  opacity: var(--card-opacity, 0.001);
  color: #fff;
}
@media (min-width: 768px) {
  .hero-marquee-item {
    --card-media-width: 273px;
    --card-media-height: 340px;
    border-radius: 32px;
  }
}

.lazyloaded .hero-marquee-item__info {
  -webkit-transform: perspective(300px) translate3d(0, 0, 0);
  transform: perspective(300px) translate3d(0, 0, 0);
  opacity: 1;
}
.hero-marquee-item__info {
  display: grid;
  position: absolute;
  z-index: 2;
  /* top: 0; */
  right: 0;
  bottom: 0px;
  left: 0;
  grid-auto-flow: row;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  padding: 12px 16px;
  -webkit-transform: perspective(300px) translate3d(0, 0, 8px);
  transform: perspective(300px) translate3d(0, 0, 8px);
  -webkit-transition: 0.6s ease-out;
  transition: 0.6s ease-out;
  -webkit-transition-delay: calc(var(--reveal-delay, 0) + 0.3s);
  transition-delay: calc(var(--reveal-delay, 0) + 0.3s);
  opacity: 0;
  background: (75.88%, rgba(0, 0, 0, 0.3));
  background: linear-gradient(
    180deg,
    transparent 51.91%,
    rgba(0, 0, 0, 0.3) 75.88%
  );
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: 8px;
}
@media (min-width: 768px) {
  .hero-marquee-item__info {
    padding: 16px 20px;
  }
}

.hero-marquee-item__info > * {
  display: -ms-flexbox;
  display: flex;
  border: 1px solid;
  border-width: thin;
  width: fit-content;
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
  border-color: #de1285;
  background-color: #de1285;
}

.hero-marquee-item__media {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 1;
  width: var(--card-media-width);
  height: var(--card-media-height);
  overflow: hidden;
}
.hero-marquee-item__media img,
.hero-marquee-item__media video {
  width: var(--card-media-width);
  height: var(--card-media-height);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.hero_Section {
  position: relative;
  padding-top: 120px;
  background-color: #f8f7f4;
  text-align: center;
}
.home_Container {
  display: flex;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  overflow: hidden;
}

.home-badge {
  display: inline-flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 10px 16px;
  border-radius: 9999999px;
  background-color: var(--home-badge-bg, #ffda79);
  color: var(--home-badge-color, #3d3d4e);
  font-family: "Source Serif 4", Georgia, serif;
  letter-spacing: -0.2px;
  line-height: 1;
}
@media (max-width: 460px) {
  .home-badge {
    font-size: 12px;
  }
}
.hero__heading {
  max-width: 860px;
  margin: 40px auto 24px;
  font-family: "Source Serif 4", Georgia, serif;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 56px;
}
.hero__heading h1 {
  font-family: "Source Serif 4", Georgia, serif;
  font-size: 56px;
}
@media (max-width: 500px) {
  .hero__heading h1 {
    font-size: 40px;
  }
}
.container_Ltext {
  margin-top: 3%;
}
.container_Ltext p {
  font-size: 20px;
  line-height: 36px;
}

/* intro Section */
.intro {
  border-bottom: 1px solid #dbdbde;
}

@media (min-width: 1060px) {
  .intro {
    border-bottom: none;
  }
}
@media (max-width: 768px) {
  .hero_Section {
    padding-top: 30px;
  }
}

.home-container {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  overflow: hidden;
  border-bottom: #3d3d4e;
}
@media (min-width: 768px) {
  .home-container {
    padding: 0 32px;
  }
}

.intro__logos {
  display: grid;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
@media (min-width: 768px) {
  .intro__logos {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    /* justify-content: space-between; */
    margin-top: 50px;
    gap: 10px;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .intro__logos {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr 1fr;
    row-gap: 44px;
    justify-items: center;
    max-width: 340px;
    margin-top: 50px;
  }
}

.intro__trusted-by {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
}

.intro__content {
  display: grid;
  padding: 130px 0;
  gap: 24px;
}

@media (min-width: 1200px) {
  .intro__content {
    gap: 90px;
  }
}

@media (min-width: 1060px) {
  .intro__content {
    grid-auto-flow: column;
    border-bottom: 1px solid #dbdbde;
    gap: 80px;
  }
}
@media (min-width: 768px) {
  .intro__content {
    padding: 150px 0;
  }
}

.intro__heading {
  font-size: 50px;
  font-weight: 300;
  line-height: 68px;
}

@media (min-width: 1060px) {
  .intro__heading {
    max-width: 530px;
    font-size: 90px;
    line-height: 100px;
  }
}
@media (min-width: 768px) {
  .intro__heading {
    font-size: 70px;
    line-height: 90px;
  }
}
.intro__main {
  display: grid;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  gap: 32px;
  font-size: 16px;
  line-height: 32px;
}

@media (min-width: 1060px) {
  .intro__main {
    padding-top: 26px;
  }
}
@media (min-width: 768px) {
  .intro__main {
    font-size: 18px;
    line-height: 36px;
  }
}

.home-page .btn2--large,
.home-page .btn2--medium {
  padding: 0 24px;
}
.home-page .btn2 {
  --btn-border-radius: 9999999px;
  padding: 0 20px;
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}
.btn2--large,
a.btn2--large {
  --btn-height: 56px;
  --btn-padding: 24px;
  --btn-font-size: 14px;
}
.btn2,
a.btn2 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: var(--btn-width, -webkit-min-content);
  width: var(--btn-width, -moz-min-content);
  width: var(--btn-width, min-content);
  height: var(--btn-height);
  padding: 0 var(--btn-padding);
  overflow: hidden;
  -webkit-transition: 0.15s cubic-bezier(0.32, 0, 0.59, 0.03);
  transition: 0.15s cubic-bezier(0.32, 0, 0.59, 0.03);
  -webkit-transition-property: color, background-color, border, border-radius,
    visibility;
  transition-property: color, background-color, border, border-radius,
    visibility;
  border: var(--btn-border-width, 1.5px) solid
    var(--btn-border-color, var(--btn-bg-color));
  border-radius: var(--btn-border-radius, 9999999px);
  background-color: #0d0c22;
  color: white;
  font-family: inherit;
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight, 600);
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
a:link,
a:visited {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: white;
  text-decoration: none;
}
.btn2 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Mhome-container {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .Mhome-container {
    padding: 0 32px;
  }
}

.connecting {
  max-width: 892px;
  margin: 0 auto;
  margin-top: 5px;
  text-align: center;
}

@media (min-width: 768px) {
  .connecting-badge {
    letter-spacing: 0;
  }
}
.connecting-badge {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 10px 16px;
  border-radius: 9999999px;
  background-color: var(--home-badge-bg, #ffda79);
  color: var(--home-badge-color, #3d3d4e);
  font-family: "Source Serif 4", Georgia, serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 1;
}

.connecting__heading {
  margin: 32px auto 24px;
}
.home-type-heading {
  font-family: "Source Serif 4", Georgia, serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 48px;
}

@media (min-width: 768px) {
  .home-type-heading {
    font-size: 64px;
    line-height: 72px;
  }
}

.home-type-body-large {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 30px;
}
@media (min-width: 768px) {
  .home-type-body-large {
    font-size: 18px;
    line-height: 36px;
  }
}

.perfect-match {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}
@media (min-width: 1060px) {
  .perfect-match {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 50px;
  }
}
@media (min-width: 768px) {
  .perfect-match {
  }
}

.perfect-match__left {
  display: grid;
  -ms-flex: 1;
  flex: 1;
}

@media (min-width: 1060px) {
  .perfect-match__left {
    max-width: 543px;
    padding-right: 24px;
  }
}

.home-type-subheading {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
}

@media (min-width: 768px) {
  .home-type-subheading {
    font-size: 40px;
    line-height: 56px;
  }
}

.home-type-body {
  font-size: 16px;
  line-height: 28px;
}

.margin-b-32 {
  margin-bottom: 32px;
}
.margin-t-24 {
  margin-top: 24px;
}
.perfect-match__actions {
  display: grid;
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  gap: 16px;
}

.perfect-match__right {
  display: grid;
  position: relative;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  max-width: 480px;
  margin: 34px auto 0;
}

@media (min-width: 1060px) {
  .perfect-match__right {
    max-width: 627px;
    margin-top: 0;
    padding: 40px;
  }
}
@media (min-width: 768px) {
  .perfect-match__right {
    max-width: 100%;
  }
}
.Story-container {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  overflow: hidden;
}
.better {
  margin-bottom: 130px;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .better {
    margin-bottom: 150px;
  }
}

@media (min-width: 768px) {
  .Story-container {
    padding: 0 32px;
  }
}
.home-type-subheading {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
}
.better__heading {
  margin-right: auto;
}
@media (min-width: 1060px) {
  .better__heading {
    max-width: 581px;
  }
}

@media (min-width: 768px) {
  .home-type-subheading {
    font-size: 40px;
    line-height: 56px;
  }
}
.better__list {
  display: grid;
  width: 100%;
  margin-top: 56px;
  gap: 48px;
}
@media (min-width: 1060px) {
  .better__list {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    margin-top: 72px;
    gap: 40px;
  }
}
.better__subheading {
  margin-bottom: 17px;
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}
.better__copy {
  font-size: 15px;
  line-height: 28px;
}
.better-testimonial {
  display: grid;
  width: 100%;
  max-width: 980px;
  margin-top: 130px;
  cursor: default;
}
@media (min-width: 1060px) {
  .better-testimonial {
    grid-template-columns: 1fr 270px;
    gap: 32px;
    margin-top: 178px;
  }
}
.better-testimonial__left {
  padding: 32px;
  border: 1px solid #dbdbde;
  border-radius: 16px;
}
.better-testimonial__quote {
  font-size: 20px;
  line-height: 32px;
}
@media (min-width: 768px) {
  .better-testimonial__quote {
    font-size: 28px;
    line-height: 43px;
  }
}
.better-testimonial__user-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 32px;
}
.better-testimonial__user-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (min-width: 768px) {
  .better-testimonial__user-info {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.better-testimonial__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

@media (min-width: 768px) {
  .better-testimonial__name {
    font-size: 20px;
    line-height: 27px;
  }
}
.better-testimonial__title {
  color: #6e6d7a;
  font-size: 14px;
  line-height: 19px;
}
@media (min-width: 768px) {
  .better-testimonial__title {
    margin-left: 0.5ch;
    font-size: 20px;
    line-height: 27px;
  }
}

.better-testimonial__right {
  display: none;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 16px;
  background: linear-gradient(57.89deg, #ff869d 21.85%, #fcd0ba 94.99%), #e54b4d;
  color: #fff;
}
@media (min-width: 1060px) {
  .better-testimonial__right {
    display: -ms-flexbox;
    display: flex;
  }
}
.why {
  margin: 130px auto;
}

@media (min-width: 768px) {
  .why {
    margin: 150px auto;
  }
}

.Whome-container {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .Whome-container {
    padding: 0 32px;
  }
}

.why__heading {
  max-width: 890px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .why__heading {
    text-align: center;
  }
}
.home-type-heading {
  font-family: "Source Serif 4", Georgia, serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 48px;
}
@media (min-width: 768px) {
  .home-type-heading {
    font-size: 64px;
    line-height: 72px;
  }
}

.why__content {
  display: grid;
  width: 100%;
  margin-top: 48px;
  gap: 56px;
}
@media (min-width: 1060px) {
  .why__content {
    grid-auto-flow: column;
    grid-template-columns: 441px 1fr;
    margin-top: 88px;
    gap: 40px;
    justify-items: end;
  }
}
.why__left {
  display: grid;
  gap: 48px;
  width: 100%;
}

@media (min-width: 1060px) {
  .why__left {
    max-width: 441px;
  }
}

.why__item {
  display: grid;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  gap: 17px;
}
.why__copy {
  padding-right: 30px;
}
.why__subheading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  height: 31px;
  margin: 0;
  padding: 0 3px;
  background-color: #e6fbfe;
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
}

.why__right {
  width: 100%;
  /* max-width: 627px; */
}
@media (min-width: 1060px) {
  .why__right {
    margin-top: 50px;
  }
}
.lazyfadein.lazyloaded {
  opacity: 1;
}
@media (min-width: 1060px) {
  .why__right img {
    border-radius: 48px;
  }
}
.why__right img {
  width: 100%;
  height: auto;
  border-radius: 24px;
}
.lazyfadein {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  opacity: 0.001;
}
.call-outs {
  display: grid;
  margin: 130px auto;
}
@media (min-width: 1060px) {
  .call-outs {
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
  }
}

@media (min-width: 768px) {
  .call-outs {
    margin: 150px auto;
    gap: 40px;
  }
}

.Chome-container {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -ms-flex-direction: column;
  /* flex-direction: column; */
  -ms-flex-align: center;
  align-items: center;
  max-width: 1200px;
  margin: 100px auto;
  overflow: hidden;
}
@media (max-width: 768px) {
  .Chome-container {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .Chome-container {
    flex-direction: column;
  }
}

.call-out {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 40px 32px;
  background-color: #f8f7f4;
  margin-top: 20px;
  border-radius: 48px;
}
@media (min-width: 768px) {
  .call-out {
    padding: 56px;
    border-radius: 48px;
    margin-top: 20px;
  }
}

.Chome-badge--teal {
  --home-badge-bg: #b3fbe3;
}

.Chome-badge {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 10px 16px;
  border-radius: 9999999px;
  background-color: var(--home-badge-bg, #ffda79);
  color: var(--home-badge-color, #3d3d4e);
  font-family: "Source Serif 4", Georgia, serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 1;
}
@media (min-width: 768px) {
  .Chome-badge {
    letter-spacing: 0;
  }
}

.home-type-subheading {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
}
@media (min-width: 768px) {
  .home-type-subheading {
    font-size: 40px;
    line-height: 56px;
  }
}

.call-out__copy {
  margin-top: 24px;
  font-size: 18px;
  line-height: 32px;
}
@media (min-width: 768px) {
  .call-out__copy {
    font-size: 20px;
    line-height: 36px;
  }
}

.call-out__actions {
  display: grid;
  margin-top: 32px;
  gap: 16px;
}

@media (min-width: 768px) {
  .call-out__actions {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
  }
}

.footer-cta {
  padding: 80px 0;
  background-color: #ffda79;
  text-align: center;
}

.footer-cta__content {
  display: grid;
  gap: 24px;
}

.Footerhome-container {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .Footerhome-container {
    padding: 0 32px;
  }
}

.home-type-heading {
  font-family: "Source Serif 4", Georgia, serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .home-type-heading {
    font-size: 64px;
    line-height: 72px;
  }
}

.footer-cta__copy {
  font-size: 18px;
  line-height: 36px;
}
.footer-cta__actions {
  display: grid;
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 16px 0 24px;
  gap: 16px;
}

.footer-cta__copy {
  font-size: 18px;
  line-height: 36px;
}
.footer-cta__copy a {
  color: inherit;
  text-decoration: underline;
  white-space: nowrap;
}

.footer-v2__upper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 44px 0;
  row-gap: 20px;
}

@media (min-width: 920px) {
  .footer-v2__upper {
    -ms-flex-direction: row;
    flex-direction: row;
    row-gap: 0;
    padding: 30px;
  }
}

.footer-v2 a,
.footer-v2 a:visited,
.footer-v2 a:active {
  color: inherit;
  text-decoration: none;
}
a:link,
a:visited {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #0d0c22;
  text-decoration: none;
}
.footer-v2__logo {
  display: -ms-flexbox;
  display: flex;
}

.footer-v2__links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  line-height: 20px;
  list-style: none;
  row-gap: 12px;
}
@media (min-width: 1200px) {
  .footer-v2__links {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    -webkit-column-gap: clamp(16px, 3.4vw, 48px);
    -moz-column-gap: clamp(16px, 3.4vw, 48px);
    column-gap: clamp(16px, 3.4vw, 48px);
  }
}

.footer-v2__link {
  font-size: 14px;
  font-weight: 600;
}
.footer-v2__social-links {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
}
.footer-v2__social-link {
  /* width: 18px;
  height: 18px; */
  color: #0d0c22;
}

/* Login page */
.main-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  background-color: #f8f7f4;
}
@media (min-width: 960px) {
  .main-container {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100%;
    overflow: hidden;
    background-color: #f8f7f4;
  }
}
.auth-sidebar {
  position: relative;
  color: #000;
}
@media (min-width: 960px) {
  .sign-in .auth-sidebar,
  .forgot-password .auth-sidebar,
  .reset-password .auth-sidebar {
    background: none;
    color: #fff;
  }
}
@media (min-width: 1100px) {
  .auth-sidebar {
    width: 400px;
  }
}
@media (min-width: 960px) {
  .auth-sidebar {
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: 450px;
  }
}
@media (min-width: 960px) {
  .auth-sidebar .auth-sidebar-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
  }
}
.auth-sidebar .auth-sidebar-video {
  display: none;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}
@media (min-width: 960px) {
  .auth-sidebar .auth-sidebar-video {
    display: block;
  }
}
.content {
  width: 100%;
}
@media (min-width: 960px) {
  .content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
  }
}
.main {
  margin: 0 auto;
  padding: 0 20px;
  /* margin-top: 200px; */
}
@media (min-width: 960px) {
  .main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
}

.auth-content {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
@media (min-width: 960px) {
  .auth-content {
    margin-left: 90px;
    padding: 30px 60px 0;
  }
}

.sign-in-form {
  margin-top: 30px;
}

.familyConatiner {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.formgroup {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 50px;
}
.formgroup p {
  font-size: "14px";
  text-align: center;
  margin-top: 10px;
}
@media (max-width: 700px) {
  .formgroup p {
    padding: 0px 20px;
  }
}
.field_Container {
  margin-top: 50px;
  padding: 0px 60px;
}

@media (max-width: 768px) {
  .field_Container {
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.formField.custom-field {
  background-image: url("./assets/location.png");
  background-repeat: no-repeat;
  background-position: right center; /* Adjust the position as needed */
  background-position: calc(100% - 30px) center; /* Move the image 30px from the right */
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  padding-right: 70px;
}

.formField.custom-select {
  background-image: url("./assets/dropdown.png");
  background-repeat: no-repeat;
  background-position: right center; /* Adjust the position as needed */
  background-position: calc(100% - 30px) center; /* Move the image 30px from the right */
  -webkit-appearance: none;
  appearance: none;
  height: 3.3rem;
  background-size: 20px;
}

/* Style the arrow icon (optional) */
.formField.custom-select::after {
  content: ""; /* Create an empty content for the arrow icon */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right position to align the arrow */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent; /* Adjust the arrow size */
  border-right: 6px solid transparent; /* Adjust the arrow size */
  border-top: 6px solid #333; /* Adjust the arrow color */
}

.group-fields {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  width: 100%;
}
@media (max-width: 768px) {
  .group-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
}

.field-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  margin-right: 20px;
}

.field-txt {
  margin-left: 10px;
}

.formField {
  border-radius: 30px;
  padding: 13px;
  width: 40%;
  border: 2px solid #f4f4f4; /* Set border color, style, and width */
  width: 90%;
  color:black;
}

.upload-field {
  position: relative;
}

.upload-icon {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  cursor: pointer;
}

.formField.with-icon {
  padding-right: 80px; /* Adjust the padding to fit the icon */
  background-image: url("./assets/upload-file.png");
  background-repeat: no-repeat;
  background-position: 90% 50%;
  cursor: pointer;
}

.donation_Field {
  border-radius: 30px;
  padding: 13px;
  width: 100%;
  border: 2px solid #f4f4f4; /* Set border color, style, and width */
}

@media (max-width: 768px) {
  .donation_Field {
    margin-bottom: 30px;
  }
}
.donation_group {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .donation_group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
}

.donation_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .form_responsive {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .formField {
    width: 100%;
    margin-bottom: 30px;
  }
}
.formField:hover {
  border-color: pink; /* Border color on focus */
  outline: none; /* Remove the default blue outline */
  box-shadow: violet;
}
.formField:focus {
  border-color: pink; /* Border color on focus */
  outline: none; /* Remove the default blue outline */
}

.Fieldform {
  border-radius: 30px;
  padding: 13px;
  width: 50%;
  border: 2px solid #f4f4f4; /* Set border color, style, and width */
}

.Fieldform:hover {
  border-color: pink; /* Border color on focus */
  outline: none; /* Remove the default blue outline */
  box-shadow: violet;
}
.Fieldform:focus {
  border-color: pink; /* Border color on focus */
  outline: none; /* Remove the default blue outline */
}

@media (max-width: 768px) {
  .Fieldform {
    width: 100%;
    margin-bottom: 30px;
  }
}

.donation_Field:hover {
  border-color: pink; /* Border color on focus */
  outline: none; /* Remove the default blue outline */
  box-shadow: violet;
}
.donation_Field:focus {
  border-color: pink; /* Border color on focus */
  outline: none; /* Remove the default blue outline */
}
.Registration_btn {
  padding: 10px;
  border-radius: 30px;
  background-color: #0d0c22;
  color: white;
  width: 15%;
  border: #000;
}
.Registration_Backbtn {
  padding: 10px;
  border-radius: 30px;
  background-color: white;
  color: #0d0c22;
  width: 15%;
  border: 1.5px solid #0d0c22;
}
.next_btn {
  /* padding: 0px 30px; */
}
@media (max-width: 768px) {
  .next_btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Registration_btn {
    width: 70%;
  }
}
.Register_btn {
  padding: 10px;
  border-radius: 30px;
  background-color: #0d0c22;
  color: white;
  width: 15%;
  border: #000;
}
.btn_conatiner {
  padding-bottom: 10px;
  display: flex;
  gap: 30px;
}

@media (max-width: 768px) {
  .btn_conatiner {
    justify-content: center;
  }
  .Register_btn {
    width: 30%;
  }
  .Registration_Backbtn {
    width: 30%;
  }
}

.Table_container {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center;  */
  /* align-items: center; Center vertically */
  margin-left:10px;
  height: auto;
  background-color: #f8f7f4;
  padding-top: 50px;
}
.Table_Section {
  /* width: 90%; */
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 40px;
}

.Table_heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 90%; */
  padding: 10px;
}
@media (max-width: 768px) {
  .Table_heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.heading_btn {
  background-color: #0d0c22;
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
  border: none;
}
@media (max-width: 400px) {
  .heading_btn {
    font-size: 12px;
    padding: 5px 7px;
  } 
  #card-header{
    font-size:20px
  }
}
.edit_btn {
  background-color: #0d0c22;
  padding: 05px 20px;
  border-radius: 30px;
  color: white;
  border: none;
  width: fit-content;
}

.table-row:hover {
  background-color: black; /* Background color on hover */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.2s; /* Smooth transition effect */
}
.Theading {
  margin-top: 15px;
}
.Tbtn {
  margin-top: 10px;
}
@media (max-width: 768px) {
  .Tbtn {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}
.table_status {
  background-color: #c4f5ff;
  padding: 8px;
  border-radius: 20px;
}
.dot {
  position: relative;
  span {
    display: block;
    width: 10px;
    height: 10px;
    background: #40ecf6;
    border-radius: 50%;
    margin-right: 10px;
  }
  &.heigh {
    background: #08a421;
  }
  &.moderate,
  &.normal {
    background: #eedc3d;
  }
  &.high {
    background-color: #d90a2c;
  }
  &.good {
    background-color: #8ef186;
  }
}

.sidebar-search {
  margin-bottom: 30px;
}

.formSearch {
  position: relative;
  overflow: hidden;
  width: 300px;
  margin-top: 15px;
}
@media (max-width: 350px) {
  .formSearch {
    width: 250px;
  }
}

.Search_input {
  height: 50px;
  background: white;
  border-radius: 25px;
  width: 100%;
  border: none;
  padding: 0 70px 0 10px;
  color: black;
  transition: border-color 0.3s ease; /* Add smooth transition for border color changes */
}

/* Apply styles when the input is focused */
.Search_input:focus {
  border-color: pink;
  outline: none; /* Remove the default blue outline */
}

/* Apply styles when hovering over the input */
.Search_input:hover {
  border-color: pink;
}

.accepted_btn {
  padding: 12px;
  border: none;
  border-radius: 30px 0px 0px 30px;
  width: 30vh;
}
.hold_btn {
  padding: 12px;
  border: none;
  width: 30vh;
}
.rejected_btn {
  padding: 12px;
  border: none;
  border-radius: 0px 30px 30px 0px;
  width: 30vh;
}

.job-details-page-container {
  margin-top: 32px;
}
@media (max-width: 768px) {
  .accepted_btn {
    width: 10vh;
    font-size: 10px;
  }
  .hold_btn {
    width: 10vh;
    font-size: 10px;
  }
  .rejected_btn {
    width: 10vh;
    font-size: 10px;
  }
}
@media (min-width: 900px) {
  .job-details-page-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.container-small {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 900px) {
  .container-small {
    padding-right: 32px;
    padding-left: 32px;
    max-width: 1152px;
  }
}
.job-details-page-container .main-content-container {
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}
.font-label {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.job-details-page-container .job-details-title {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
@media (min-width: 768px) {
  .job-details-page-container .job-details-title {
    font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
  }
}

.margin-t-16 {
  margin-top: 16px;
}
.margin-t-20 {
  margin-top: 20px;
}
.display-flex {
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 768px) {
  .display-flex {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}
.btn2--tertiary,
a.btn2--tertiary {
  --btn-bg-color: #fff;
  --btn-bg-color-hover: #fff;
  --btn-text-color: #0d0c22;
  --btn-text-color-hover: #6e6d7a;
  --btn-border-color: #e7e7e9;
  --btn-border-color-hover: #dbdbde;
  --btn-text-color-hover: var(--btn-text-color);
}
.btn2,
a.btn2 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: var(--btn-width, -webkit-min-content);
  width: var(--btn-width, -moz-min-content);
  width: var(--btn-width, min-content);
  height: var(--btn-height);
  padding: 0 var(--btn-padding);
  overflow: hidden;
  -webkit-transition: 0.15s cubic-bezier(0.32, 0, 0.59, 0.03);
  transition: 0.15s cubic-bezier(0.32, 0, 0.59, 0.03);
  -webkit-transition-property: color, background-color, border, border-radius,
    visibility;
  transition-property: color, background-color, border, border-radius,
    visibility;
  border: var(--btn-border-width, 1.5px) solid
    var(--btn-border-color, var(--btn-bg-color));
  border-radius: var(--btn-border-radius, 9999999px);
  background-color: var(--btn-bg-color);
  color: var(--btn-text-color);
  font-family: inherit;
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight, 600);
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 13px 20px;
}
a:link,
a:visited {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #0d0c22;
  text-decoration: none;
}
@media (max-width: 768px) {
  .btn2,
  a.btn2 {
    margin-bottom: 20px;
    /* width: 120px; */
    padding: 15px 20px;
  }
}
.margin-r-16 {
  margin-right: 16px;
}
.btn2 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.job-details-page-container .job-details-description {
  margin-top: 40px;
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.margin-t-16 {
  margin-top: 16px;
}
.sidebar-container {
  width: 100%;
  margin-top: 32px;
}

@media (min-width: 768px) {
  .sidebar-container {
    max-width: 380px;
    margin-top: 0;
    /* margin-left: 40px; */
    padding-top: 40px;
  }
}
.sidebar_edit {
  display: flex;
  /* justify-content: end; */
  margin-bottom: 20px;
}
.sidebar-content-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 48px;
  padding: 32px;
  border: 1px solid #e7e7e9;
  border-radius: 8px;
}
@media (max-width: 900px) {
  .sidebar-content-container {
    border: none;
    padding: 0;
  }
}
.margin-t-24 {
  margin-top: 24px;
}
.family-status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .family-status {
    display: flex;
    flex-direction: column;
  }
}
.font-sublabel {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.margin-t-8 {
  margin-top: 8px;
}
.font-label {
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.detailDiv {
  display: flex;
  flex-wrap: wrap;
}

.detailDiv > div {
  flex: 1;
  margin-right: 40px; /* You can adjust the margin as needed */
}

@media (max-width: 567px) {
  .detailDiv > div {
    /* margin-right: 0; */
    margin-bottom: 20px; /* Add margin between rows on smaller screens */
  }
}
.detail_MainConatiner {
  padding: 0px 0px;
}
.back-btn {
  position: absolute;
  top: 40px;
  left: 40px;
}
.btn2--tertiary,
a.btn2--tertiary {
  --btn-bg-color: #fff;
  --btn-bg-color-hover: #fff;
  --btn-text-color: #0d0c22;
  --btn-text-color-hover: #6e6d7a;
  --btn-border-color: #e7e7e9;
  --btn-border-color-hover: #dbdbde;
  --btn-text-color-hover: var(--btn-text-color);
}

.btn2--circle,
a.btn2--circle {
  --btn-width: var(--btn-height);
  --btn-padding: 0;
  --btn-border-radius: 50%;
}
.backButton {
  padding: 5px;
  /* margin-top: 50px; */
  z-index: 2;
  width: 35px;
  position:fixed;
}
@media (min-width: 768px) {
  .backButton {
    padding: 5px;
    margin-top: 50px;
    z-index: 2;
  }
}

/* About us */

.framer-ej3j9 .framer-16ep3vi {
  border-radius: 16px;
  flex: none;
  height: 700px;
  max-width: 1200px;
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: var(--framer-will-change-override, transform);
}

@media (min-width: 1100px) and (max-width: 1439px) {
  .framer-ej3j9 .framer-16ep3vi {
    height: 620px;
  }
}

.img_container {
  border-radius: 50px;
  padding: 20px;
}

.sponsor-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sponsor-btn {
  background-color: rgb(222, 18, 133);
  border-radius: 30px;
  padding: 10px 20px;
  border: none;
  color: white;
  width: fit-content;
}
.goal_container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .goal_container {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .goal_container {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .goal_container {
    max-width: 1100px;
  }
}
.sec-title {
  position: relative;
  display: block;
  margin-top: -4px;
  padding-bottom: 56px;
}
.sec-title .sub-title {
  position: relative;
  display: block;
  margin-top: 30px;
  padding-bottom: 11px;
}
.sec-title h2 {
  font-size: 40px;
  line-height: 1.3em;
  font-weight: 800;
  text-transform: none;
}
.goal_row {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.mission-goals-content {
  position: relative;
  display: block;
  min-height: 575px;
  z-index: 1;
}
.mission-goals-content .text-holder ul {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.mission-goals-image-box {
  background-image: url("./assets/donation_card\ \(2\).jpg");
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 585px;
  height: 550px;
  width: 100%;
  overflow: hidden;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
}

.mission-goals-content .text-holder {
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 60px;
  max-width: 640px;
  width: 100%;
  background: #ffffff;
  padding: 53px 60px 63px;
  box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
  z-index: 3;
  height: 400px;
}
@media (max-width: 800px) {
  .mission-goals-image-box {
    display: none;
  }
  .mission-goals-content .text-holder {
    max-width: fit-content;
    box-shadow: none;
    padding: 0;
  }
}
.mission-goals-content .text-holder .top {
  position: relative;
  display: flex;
  align-items: center;
}
.mission-goals-content .text-holder .top .icon {
  max-width: 70px;
  width: 100%;
}
.mission-goals-content .text-holder .top .icon {
  max-width: 70px;
  width: 100%;
}
.mission-goals-content .text-holder .top .title {
  position: relative;
  display: block;
}
.mission-goals-content .text-holder .top .title h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
.mission-goals-content .text-holder ul li .icon .shape {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: -1;
}

.buttonConatiner {
  display: flex;
  flex-direction: row;
  /* justify-content: end; */
  gap: 10px;
}
@media (max-width: 500px) {
  .buttonConatiner {
    flex-direction: column;
    gap: 0px;
  }
}
/* DonationForm.css */

.donation_conatiner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
  width: 40%;
  transform: translate(70%, 20%);
}

.Donationform-container {
  width: 100%;
  height: 100%;
  background-color: #f8f7f4;
  /* max-width: 500px;
  margin: auto; */
  display: block;
  /* left      : 50%;
  top       : 50%; */
  position: absolute;
  /* transform : translate(-50%, -50%); */
  border-radius: 20px;
}

.input {
  border-radius: 10px;
  padding: 10px 30px;
  font-family: "Saira", sans-serif;
  border: 0.5 px solid black;
  border-color: black;
  width: 100%;
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.nav-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.line {
  border-bottom: 1px solid;
  border-color: palevioletred;
  margin-top: 30px;
  margin-bottom: 30px;
}

.stripe_card {
  /* width: 30vw;
  min-width: 500px; */
  margin: auto;
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
}

.scard_container {
  display: flex;
  flex-direction: column; /* Align items in a column */
  align-items: center; /* Center items horizontally */
  width: 90px;
  background: white;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.card_image_container {
  margin-top: 10px;
  margin-bottom: -5px; /* Adjust the spacing between image and label */
}
.scard_container.active {
  border: 2px solid blue;
}

.stripe_input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 40px;
}

/* Sidebar.css */

/* Sidebar.css */

.sidebar {
  height: 100%;
  width: 220px;
  /* position: fixed; */
  top: 40px;
  /* left: 0; */
  padding-top: 20px;
  color: #fff;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sidebartoglle{
 display: none; 
}
@media(max-width:850px){
  .sidebar{
    background-color: black;
    padding: 15px;
    z-index: 10;
    top :0;
    transition: 0.3s ease;
  }
  .sidebartoglle{
  display: block;
  font-size: x-large;
  border-radius: 50%;
  transition: 0.3s ease;
}
}


.sidebar.collapsed {
  width: 60px;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.sidebar ul {
  padding: 20px 0;
}

.sidebar li {
  padding: 15px 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  margin-bottom: 20px;
  background-color: #343a40;
  margin-left: 20px;
  border-radius: 15px;
}

.sidebar a {
  text-decoration: none;
  color: #fff;
}

.sidebar li a.active {
  background-color: #007bff; /* Change this to your desired active link color */
  color: #fff;
}

.content-wrapper {
  display: flex;
  background-color: #f8f7f4;
}
@media (max-width: 768px) {
  .content-wrapper {
    background-color: #f8f7f4;
  }
}

.dashboard-content {
  flex: 1;
  padding: 20px; 
  margin-left: 230px;
  /* margin-top: 20px; */
}

@media (max-width: 850px) {
  .dashboard-content {
    margin-left: 0px;
    padding: 0px;
  }
}

.Admindashboard__task__wrapper {
  .dashboardCard {
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    position: relative;
    padding: 25px;
    background: white;
    border-radius: 20px;
    transition: all 0.4s ease-in-out;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
      font-size: 22px;
      font-weight: bold;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fafafa;
        transition: all 0.4s ease-in-out;
      }

      button {
        color: #fff;
        font-size: 40px;
        border: 0;
        background: transparent;
      }
    }

    h6 {
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 0;
      color: #ffffff;
    }

    p {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      color: #ffffff;
    }

    .contentCard {
      h6 {
        margin-bottom: 10px;
      }

      a {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .task-card {
    color: var(--text-white);
    padding: 35px;
    border-radius: 25px;
    height: 100%;

    .task-header {
      position: relative;
    }

    .task-header::after {
      content: "";
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: -15px;
      left: 0;

      background-color: #857878;
    }

    li {
      margin-left: 20px;
    }
  }

  .tasks-content {
    margin-top: 60px;
    position: relative;

    .cardItems {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .tasks-heading {
      h4 {
        position: relative;
        margin-left: 40px;

        &::before {
          content: "";
          height: 20px;
          width: 20px;
          position: absolute;
          background-color: orange;
          border-radius: 100%;
          left: -30px;
          top: 5px;
        }

        &.task::before {
          background-color: #e40707;
        }

        &.todo::before {
          background-color: #40ecf6;
        }

        &.in-progress::before {
          background-color: #f2de2c;
        }

        &.done::before {
          background-color: #08a421;
        }
      }
    }

    .task-card-item {
      border-radius: 25px;
      background: #141414;
      padding: 15px;
      .categories {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .category {
          height: 25px;
          width: fit-content;
          padding: 3px 10px;
          border-radius: 25px;
          background-color: orange;
          cursor: pointer;
          overflow: hidden;

          &:nth-child(odd) {
            background-color: #212529;
          }

          &:nth-child(even) {
            background-color: #212529;
          }

          p {
            font-size: 12px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: var(--text-black);
          }
        }

        .category_sprint {
          height: 25px;
          width: fit-content;
          padding: 3px 10px;
          border-radius: 0px;
          background-color: orange;
          cursor: pointer;
          overflow: hidden;

          &:nth-child(odd) {
            background-color: #80bbf0;
          }

          &:nth-child(even) {
            background-color: #ff90e7;
          }

          p {
            font-size: 12px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: var(--text-black);
          }
        }
      }

      p.title {
        font-size: 16px;
        font-weight: 500;
        margin: 15px 0px;
      }

      p.description {
        font-size: 14px;
        font-weight: 400;
      }

      .card-footer {
        display: flex;
        gap: 10px;
        border-top: 1px solid red;
        margin-top: 15px;

        .func-btn {
          height: 25px;
          width: fit-content;
          background-color: #212529;
          padding: 1px 10px;
          text-align: center;
          font-size: 13px;
          cursor: pointer;

          button {
            color: var(--text-white);
            position: relative;
            background: transparent;
            border: 0;

            &.view {
              padding: 1px 7px;
            }
          }

          button.view::before {
            content: "";
            height: 5px;
            width: 5px;
            background-color: #40ecf6;
            position: absolute;
            left: -5px;
            top: 7px;
            border-radius: 100%;
          }
        }
      }
    }
  }

  .filter {
    a {
      cursor: pointer;

      p {
        color: var(--text-white);
        font-weight: 500;
        font-size: var(--desc);
      }
    }
  }
}

.cardTop {
  margin-top: 0px;
}

@media (max-width: 768px) {
  .cardTop {
    margin-top: 25px;
  }
}

.custom-modal {
  background: #de1285;
  border-radius: 20px;
}

.custom-modal .modal-content {
  background: #de1285; /* Set the background color for the modal content */
  border-radius: 20px;
}

.StripeCard_box {
  position: absolute;
  top: 80px;
  right: 0;
  bottom: 60px;
  max-width: 640px;
  width: 100%;
  background: #ffffff;
  padding: 40px 40px 43px;
  box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
  z-index: 3;
  height: fit-content;
  border-radius: 20px;
}

/* Filter Sidebar */

.filter_Container {
  display: flex;
  flex-direction: row;
  background-color: #f8f7f4;
  min-height: 100vh;
}

@media (max-width: 768px) {
  .filter_Container {
    display: flex;
    flex-direction: column;
  }
}

.filter_sidebar {
  display: flex;
  flex-direction: column;
  padding: 30px;
  /* Remove the transform property */
  /* transform: translate(10px, 160px); */
  position: relative;
  background-color: white;
  border-radius: 20px;
  height: 100%;
}

.heading {
  font-size: 20px;
  font-weight: bold;
}
.line_tag {
  border: 1px solid;
  border-color: #ececee;
  margin-bottom: 20px;
}
.filter_label {
  display: flex;
  align-items: center;
  color: #8d8d8d;
}
.filter_box {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  gap: 8px;
}
.checkbox {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.status-dropdown {
  margin-top: 10px;
  position: absolute;
  background: #ececee;
  padding: 20px 30px 20px 10px;
  border-radius: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.href_button {
  width: fit-content;
  background-color: #de1285;
  padding: 7px 13px;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  color: white;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
  width: 50px;
}

.userCard {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  background: transparent;
  border: 1px solid #de1285;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;
  flex-wrap: nowrap;
}
.card_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.mainCard {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 500px) {
  .infoContainer {
    display: block;
  }
}
.infoCard {
  display: flex;
  flex-direction: column;
}
.container_heading {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.Application_contentCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.propertyListing {
  margin-left: 30px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .propertyListing {
    margin-left: 0px;
  }
}

.PhoneInputInput {
  border: none;
}
input.PhoneInputInput {
  background: white;
  color: black;
  padding: 13px;
}
input.PhoneInputInput:focus {
  background: white;
  color: black;
  border: none;
  box-shadow: none;
  outline: none;
}

.hover-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 100px; /* Adjust the positioning as needed */
  gap: 20px;
}

.event_action-btn {
  background-color: #d4d4d4;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.event_action-btn:hover {
  background-color: #ad0d6b;
}
.dashboard-responsive {
  display: flex;
  justify-content: center;
  width: 85%;
}
.bard {
  height: 500px;
}

.program_main {
  display: flex;
  /* justify-content: center; */
  gap: 30px;
  flex-wrap: wrap;
  margin-top:20px;
}
.card_content{
  opacity: 0;
  position: absolute;
  bottom: -44px;
  background-color: #DE1285;
transition: 0.5s ease-in-out;
}
.card_main:hover .card_content{
opacity: 1;
bottom: 0;
transition: 0.5s ease-in-out;
}

.program_card {
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.program_inner_card {
  width: 300px;
  height: 400px;
  border-style: double;
}
@media (max-width: 400px) {
  .program_card {
    width: 250px;
  }
  .program_inner_card {
    width: 250px;
  }
}
section  .active{
  border-right: 4px solid white;
  background: rgb(45, 51,89);
  color: white;
}
.capture_fingerprint{
  margin-left:-200px;
}
@media (max-width: 850px) {
  .capture_fingerprint{
    margin-left:0px;
  }
}

.scan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:150px;
  span.border_btm {
    &:after {
      display: block;
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: -10px;
      right: -10px;
      border-bottom: 3px solid #DE1285;
      border-right: 3px solid #DE1285;
    }
    &:before {
      display: block;
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: -10px;
      left: -10px;
      border-bottom: 3px solid #DE1285;
      border-left: 3px solid #DE1285;
    }
  }
  &:before {
    display: block;
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    top: -10px;
    left: -10px;
    border-top: 3px solid #DE1285;
    border-left: 3px solid #DE1285;
  }
  &:after {
    display: block;
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    top: -10px;
    right: -10px;
    border-top: 3px solid #DE1285;
    border-right: 3px solid #DE1285;
  }
}
.scan .fingerprint {
  position: relative;
  width: 150px;
  height: 200px;
background: url('./assets/fingerPrint.png');
  background-size: 150px;
}
.scan .fingerprint::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/fingerPrint2.png');
  background-size: 150px;
  animation: animate 4s ease-in-out infinite;
  filter: brightness(0) saturate(100%) invert(31%) sepia(92%) saturate(6904%) hue-rotate(314deg) brightness(88%) contrast(97%);
}
@keyframes animate {
  0%,
  100% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
}
.scan .fingerprint::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: #DE1285;
  border-radius: 8px;
  filter: drop-shadow(0 0 20px #DE1285) drop-shadow(0 0 60px #DE1285);
  animation: animate_line 4s ease-in-out infinite;
}
@keyframes animate_line {
  0%,
  100% {
    top: 0%;
  }
  50% {
    top: 95%;
  }
}
.scanning_text{
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  margin-top: 20px;
  color: #DE1285;
  filter: drop-shadow(0 0 20px #3fefef) drop-shadow(0 0 60px #3fefef);
  animation: animate_text 0.5s steps(1) infinite;
}
@keyframes animate_text {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}


.highlighted_underline .feedbutton {
  position: relative;
  transition: 1s all ease;
}
.feedbutton:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: -5px;
  top:28px;
  left: 0;
  position: absolute;
  background-color: black;
  transition: 1s all ease;
}
.feedbutton.active:after {
  width: 100%;
}
.grid-2 {
  grid-template-columns: repeat(2, 1fr);
  }
  
  .grid-3s {
  grid-template-columns: repeat(3, 1fr);
  }
  
  .grid-4 {
  grid-template-columns: repeat(2, 1fr);
  }
  
  .grid-5 {
  grid-template-columns: repeat(3, 1fr);
  }
  
  .grid-6 {
  grid-template-columns: repeat(3, 1fr);
  }
  
  .grid-7 {
  grid-template-columns: repeat(4, 1fr);
  }
  
  .grid-8 {
  grid-template-columns: repeat(4, 1fr);
  }
  
  .grid-9 {
  grid-template-columns: repeat(3, 1fr);
  }
  
  .single-image {
  width: 50%;
  object-fit: fill;
  padding: 10px;
  }
  
  .image-2 {
  grid-column: span 2;
  grid-row: span 1;
  }
  
  .image-3 {
  grid-column: span 3;
  grid-row: span 1;
  }
  
  .image-4 {
  grid-column: span 2;
  grid-row: span 2;
  }
  
  .image-5 {
  grid-column: span 3;
  grid-row: span 2;
  }
  
  .image-6 {
  grid-column: span 3;
  grid-row: span 2;
  }
  
  .image-7 {
  grid-column: span 4;
  grid-row: span 2;
  }
  
  .image-8 {
  grid-column: span 4;
  grid-row: span 2;
  }
  
  .image-9 {
  grid-column: span 3;
  grid-row: span 3;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    display: none;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size:30px;
    cursor:pointer;
    }
    .familyRegistration{
      background-image: url('./assets/family.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .medicalStoreRegistration{
      background-image: url('./assets/medicalStore.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .postCase{
      background-image: url('./assets/charityhelp.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    }

.program_detail_slider{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(72deg, #DE1285 10.37%, hsla(0, 0%, 85.1%, 0) 63.83%); */
  background-image: linear-gradient(92deg, rgba(67, 66, 66, .99) 12.37%, hsla(0, 0%, 85.1%, 0) 63.83%);
  pointer-events: none;
}
.cmnt_text_btn{
  display:none;
}
.cmnt_text:hover .cmnt_text_btn{
  display: block;
}